@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

h1 {
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  /* background-color: black;
  color: transparent;
  text-shadow: 0px 2px 3px rgba(255, 255, 255, 0.3);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text; */
  font-size: 40px;
  font-family: "Manrope";
  font-weight: 600;
}

h2 {
  overflow: hidden;
  color: white;
  text-overflow: ellipsis;
  font-size: 20px;
  font-family: "Manrope";
  font-weight: 200;
}

.click {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}


@keyframes moveGradients {
  0% {
    --x1: 20%;
    --y1: 30%;
    --x2: 70%;
    --y2: 80%;
    --x3: 50%;
    --y3: 50%;
  }

  50% {
    --x1: 25%;
    --y1: 35%;
    --x2: 65%;
    --y2: 75%;
    --x3: 45%;
    --y3: 45%;
  }

  100% {
    --x1: 30%;
    --y1: 40%;
    --x2: 60%;
    --y2: 70%;
    --x3: 40%;
    --y3: 40%;
  }
}